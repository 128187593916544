<template>
  <exterior-page-content-container>
    <p class="text-2xl font-bold text-center text-primary">
      Home Safety, Decluttering, Downsizing App
    </p>

    <p class="text-lg text-center text-content text-bold">
      Senior’s Independence Support Technology
    </p>

    <button-large
      class="mt-5"
      @click="$router.push({name: 'Register'})"
      color="primary"
    >
      Create Account
    </button-large>

    <p class="mt-2 text-lg text-content text-bold">
      Already have an account?
      <a
        class="underline cursor-pointer text-content"
        @click="$router.push({name: 'SignIn'})"
      >
        Sign in
      </a>
    </p>
  </exterior-page-content-container>
</template>

<script>
import ExteriorPageContentContainer from '@/components/shared/containers/ExteriorPageContentContainer.vue';
import ButtonLarge from '../components/shared/Buttons/ButtonLarge.vue';

export default {
  name: 'Landing',
  components: {
    ExteriorPageContentContainer,
    ButtonLarge,
  },
};
</script>
